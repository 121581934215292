/* -----------------------------------------------

Table of Content

	1. Body and Core Css
  2. NavBar Section
  3. Banner Section 
     - 3.1 Content
     - 3.2 Point
  4. About Section
  5. Work section
  6. Clients section
  7. Price section
  8. Footer

----------------------------------- */

/* -----------------------------------
		      1. Body and Core Css
----------------------------------- */
* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  outline: none !important;
  list-style: none !important;
  text-decoration: none !important;
  scroll-behavior: smooth;
}

*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background: #010314;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

a,
a:hover {
  color: #fff;
  outline: none;
  text-decoration: none;
}

p {
  color: #92939e;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 500;
}

img,
video {
  max-width: 100%;
}

.d-flex-center {
  display: flex;
  justify-content: center;
}

dl,
ol,
ul {
  padding: 0;
}

/* Button */
.btn-st {
  position: relative;
  overflow: hidden;
  font-size: 14px;
  background: #ffffff;
  color: #010314;
  font-weight: 700;
  padding: 14px 23px;
  border-radius: 30px;
  border: none;
  z-index: 2;
  transition: all 0.3s;
}

.btn-st span {
  position: relative;
  z-index: 2;
}

.btn-st::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: linear-gradient(82.3deg, #df86aa 10.8%, #5729d6 94.3%);
  transition: all 0.475s;
  z-index: 0;
  opacity: 0;
}

.btn-st:hover {
  background: #df86aa;
  color: #ffffff;
}

.btn-st:hover::after {
  opacity: 1;
}

.btn2 {
  padding: 14px 40px;
}

.text-left {
  text-align: left;
}

/* -----------------------------------
                2. NavBar Section
  ----------------------------------- */
.navbar-custom {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  transition: all 0.3s;
  z-index: 999;
}

.navbar-custom .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-custom .container .left-side {
  display: flex;
  align-items: center;
}

.navbar-custom .container .left-side .logo img {
  max-width: 90px;
}

.navbar-custom .container .info {
  display: flex;
  flex-direction: column;
}

.navbar-custom.fixed {
  position: fixed;
  height: 80px;
  background-color: #000;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
}

/* 3 Banner */
.banner {
  position: relative;
  overflow: hidden;
  padding: 300px 0 250px;
  z-index: 2;
}

.banner .content {
  position: relative;
  text-align: left;
  z-index: 2;
}

.banner .content h1 {
  font-size: 200px;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 1;
  margin-left: -11px;
  margin-top: -11px;
  margin-bottom: 65px;
}

.banner .content h1 span {
  position: relative;
  margin-left: 15px;
}

.banner .content h1 span img {
  position: absolute;
  top: 50px;
  left: -40px;
}

.banner .content .subtitle {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.02em;
  background: linear-gradient(90deg, #df86aa -18.55%, #5729d6 163.09%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.banner .content .btn-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.banner .content .btn-wrap h3 {
  display: inline-block;
  font-size: 56px;
  font-weight: 700;
  line-height: 1.607;
  background: linear-gradient(180deg, #e5f8c6, #61b998);
  border-radius: 25px;
  color: #010314;
  padding: 24px 40px;
  margin-bottom: 0;
}

.banner .content .btn-wrap .info {
  max-width: 445px;
}

.banner .content .btn-wrap .info p {
  margin-bottom: 30px;
}

.banner .hero-bg-gradient1 {
  width: 969px;
  height: 722px;
  position: absolute;
  background: radial-gradient(
    48.69% 39.15% at 50.02% 50.04%,
    #cd22f880 0.01%,
    #c809f94d 23.96%,
    #42005300 90%
  );
  top: -5%;
  right: 0;
  transform: rotate(40deg);
  z-index: -1;
}

.banner .hero-bg-gradient2 {
  width: 721px;
  height: 539px;
  position: absolute;
  background: radial-gradient(
    48.69% 39.15% at 50.02% 50.04%,
    #53ffc1 0.01%,
    #1936634d 53.96%,
    #0000
  );
  top: 25%;
  left: -448px;
  transform: rotate(40deg);
  opacity: 0.7;
  z-index: -1;
}

.banner .hero-gradient-ball {
  background: radial-gradient(
    114.99% 94.16% at 72.92% 82.18%,
    #111 47.39%,
    #091534 68.23%,
    #a24dd6 86.63%
  );
  height: 350px;
  width: 350px;
  border-radius: 50%;
  position: absolute;
  right: 40px;
  bottom: 10%;
  z-index: -1;
  opacity: 0.5;
  animation-name: alltuchtopdown;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.banner .ripple-shape {
  transform: skew(220deg);
  position: relative;
  z-index: -1;
  top: -700px;
  left: 240px;
  opacity: 0.3;
}

.banner .ripple-1,
.banner .ripple-2,
.banner .ripple-3,
.banner .ripple-4,
.banner .ripple-5 {
  width: 700px;
  height: 700px;
  position: absolute;
  left: 30%;
  top: 0;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 1px solid #df86aa;
  border-radius: 50%;
  animation: ripple2 10s linear infinite;
  opacity: 0;
  transition: 0.4s;
}

.banner .ripple-1 {
  animation-delay: 0;
}
.banner .ripple-2 {
  animation-delay: 1s;
}
.banner .ripple-3 {
  animation-delay: 3s;
}
.banner .ripple-4 {
  animation-delay: 5s;
}
.banner .ripple-5 {
  animation-delay: 6s;
}

/* About */
.about {
  position: relative;
  padding-top: 250px;
  padding-bottom: 140px;
  background-image: url(../assets/img/about-bg.png);
  background-size: cover;
  background-position: center;
}

.about .card {
  display: flex;
  position: relative;
  width: calc(100% - 30px);
  border-radius: 25px;
  background: #13141e;
  align-items: center;
  flex-direction: row;
  margin-left: 20px;
  margin-bottom: 40px;
}

.about .card .details {
  padding: 50px;
  width: 100%;
  color: #fff;
  text-align: left;
}

.about .card .details ul li {
  position: relative;
  margin-bottom: 15px;
  padding-left: 20px;
}

.about .card .details ul li::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  top: 8px;
  left: 5px;
  border-radius: 50%;
  background-color: #fff;
}

.about .card .image {
  transform: translate(134px);
  margin-left: -134px;
  flex: none;
}

.about .images {
  position: relative;
  text-align: center;
  z-index: 2;
  margin-bottom: 30px;
}

.about .images img {
  max-width: 40%;
  opacity: 0.5;
}

.about .btn-block {
  display: flex;
  gap: 10px;
  padding: 15px;
  border-radius: 40px;
  background: linear-gradient(180deg, #22222b, #46474e);
}

.about .btn-block .btn-st {
  font-size: 30px;
  padding: 40px 70px;
}

.about .wallet {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(180deg, #131524, #080a1a);
}

.about .wallet p {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  font-size: 26px;
}

.about .wallet p span {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  background: linear-gradient(90deg, #df86aa -18.55%, #5729d6 163.09%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.feature-area-shape {
  position: absolute;
  left: -100px;
  top: 200px;
}

.feature-area-shape .feature-shape2-1 {
  animation-name: alltuchtopdown;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.feature-area-shape .feature-shape2-2 {
  position: absolute;
  bottom: -30px;
  right: 30px;
  animation-name: alltuchtopdown;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.feature-area-shape .feature-shape2-3 {
  position: absolute;
  right: 0;
  top: 0;
  animation-name: leftToRight;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.coins {
  position: absolute;
  width: 80%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.coins:last-child {
  transform: scaleX(-1) translateX(50%);
  top: 70%;
  opacity: 0.7;
}

.coins img {
  position: absolute;
  transform: scale(0.5);
}

.coins img:nth-child(1) {
  top: 90px;
  right: 0;
  opacity: 0.5;
}

.coins img:nth-child(2) {
  top: 50px;
  right: 27%;
  opacity: 0.5;
  max-width: 100px;
  animation-name: alltuchtopdown;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.coins img:nth-child(3) {
  top: 0;
  left: 27%;
  opacity: 0.5;
}

.coins img:nth-child(4) {
  top: 25%;
  left: 18%;
  opacity: 0.5;
  max-width: 115px;
  animation-name: alltuchtopdown;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.coins img:nth-child(5) {
  top: 50%;
  left: 3%;
  opacity: 0.5;
}

.coins img:nth-child(6) {
  top: 24%;
  left: 49%;
  opacity: 0.5;
  animation-name: alltuchtopdown;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* Partners */
.partners {
  position: relative;
  padding-top: 50px;
  padding-bottom: 100px;
}

.partners .subtitle {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.02em;
  background: linear-gradient(90deg, #df86aa -18.55%, #5729d6 163.09%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  margin-top: -0.4em;
  position: relative;
  top: -6px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.partners .slider {
  padding: 50px 0;
}

.partners .slider img {
  opacity: 0.5;
}

/* CTA */
.cta {
  position: relative;
  padding: 50px 0 100px;
}

.cta .box {
  background: linear-gradient(180deg, #e5f8c6, #61b998);
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  padding-right: 130px;
}

.cta .box .details {
  padding: 75px 100px;
  max-width: 740px;
  text-align: left;
}

.cta .box .details h4 {
  color: #010314;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 0;
  letter-spacing: -1.5px;
}

.cta .box .details p {
  color: #010314;
  font-size: 16px;
  margin-top: 30px;
  line-height: 1.667;
  margin-bottom: 0;
}

.cta .box .details .btn-block {
  display: block;
  margin-top: 40px;
}

.cta .box .images {
  margin-top: -90px;
}

.cta .box .images img {
  animation-name: alltuchtopdown;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

/* Footer */
.footer {
  position: relative;
  padding: 50px 0;
}

.footer .logo {
  max-width: 90px;
  margin-bottom: 30px;
}

.footer p {
  margin-bottom: 30px;
}

.footer .social a {
  height: var(--icon-size, 48px);
  width: var(--icon-size, 48px);
  line-height: 48px;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  margin: 0 5px;
  border-radius: 50%;
  background: #ffffff14;
  transition: all 0.3s;
}

.footer .social a:hover {
  background: #df86aa;
}

.footer .social a img {
  max-width: 20px;
}

.footer .copy {
  margin: 30px 0 0;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}

.toast {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99;
  font-weight: 700;
  color: #d94c48;
  border: 1px solid #d94c48;
  background: rgb(44 17 30);
}

.toast-header {
  background: transparent;
  border: none;
  color: #d94c48;
  font-weight: 300;
}

.toast-header .btn-close {
  filter: invert(1);
}

/* Responsive */
@media screen and (max-width: 1400px) {
  .banner .content h1 {
    font-size: 160px;
  }

  .banner .content .btn-wrap h3 {
    font-size: 35px;
  }

  .banner .hero-bg-gradient1 {
    right: -40%;
  }
}

@media screen and (max-width: 1200px) {
  .banner {
    padding: 300px 0 130px;
  }

  .banner .content h1 {
    font-size: 120px;
  }

  .banner .content .btn-wrap h3 {
    font-size: 35px;
  }

  .banner .content h1 span img {
    top: 35px;
    left: -15px;
  }

  .banner .content .btn-wrap {
    align-items: flex-start;
    flex-direction: column;
  }

  .banner .hero-gradient-ball {
    height: 250px;
    width: 250px;
  }
  .about .card {
    flex-direction: column;
    z-index: 2;
  }

  .about .card .details {
    padding: 50px;
  }

  .about .card .image {
    transform: translate(0);
    margin-top: -20px;
    margin-left: 0;
    margin-bottom: 20px;
    flex: none;
  }

  .about .btn-block .btn-st {
    font-size: 20px;
  }

  .partners .slider {
    padding: 50px 0 0;
  }

  .slick-slide {
    margin: 0 15px;
  }

  .cta {
    position: relative;
    padding: 0 0 100px;
  }

  .cta .box {
    padding-right: 50px;
  }

  .cta .box .details {
    padding: 50px;
  }

  .cta .box .images {
    margin-top: 0;
  }

  .coins {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .banner .content h1 {
    font-size: 95px;
  }

  .about .btn-block .btn-st {
    padding: 40px;
  }

  .cta .box .details h4 {
    font-size: 30px;
  }

  .cta .box .details p {
    font-size: 14px;
  }

  .cta .box .images {
    margin: auto;
  }

  .coins img {
    position: absolute;
    transform: scale(0.4);
  }

  .coins img:nth-child(1) {
    top: 90px;
    right: 0;
    opacity: 0.5;
    max-width: 250px;
  }

  .coins img:nth-child(4) {
    left: 6%;
  }

  .coins img:nth-child(6) {
    left: 49%;
  }

  .coins img:nth-child(6) {
    top: 33%;
    left: 44%;
    max-width: 136px;
  }

  .coins img:nth-child(3) {
    top: 80px;
    left: 27%;
    opacity: 0.5;
  }
}

@media screen and (max-width: 767px) {
  .banner {
    padding: 200px 0 130px;
  }

  .banner .content h1 {
    font-size: 60px;
    padding-left: 8px;
    padding-top: 15px;
  }

  .banner .content h1 span {
    margin-left: 3px;
  }

  .banner .content h1 span img {
    top: 7px;
    left: 1px;
  }

  .banner .content .btn-wrap h3 {
    font-size: 20px;
  }

  .banner .hero-gradient-ball {
    height: 150px;
    width: 150px;
  }

  .about .card {
    margin: 0 auto 40px;
  }

  .about .card .details {
    padding: 30px;
  }

  .about .images img {
    max-width: 70%;
  }

  .about .btn-block {
    flex-direction: column;
  }

  .cta .box {
    flex-direction: column;
    padding-right: 0;
  }

  .cta .box .images img {
    max-width: 50%;
  }

  .feature-area-shape {
    display: none;
  }

  .coins img {
    position: absolute;
    transform: scale(0.3);
  }

  .coins img:nth-child(3) {
    top: 40px;
    left: 1%;
    opacity: 0.5;
  }

  .coins img:nth-child(1) {
    top: 90px;
    right: -60px;
    opacity: 0.5;
    max-width: 250px;
  }
}

/* Animation */
@keyframes alltuchtopdown {
  0% {
    transform: rotateX(0) translateY(0);
  }
  50% {
    transform: rotateX(0) translateY(-30px);
  }
  100% {
    transform: rotateX(0) translateY(0);
  }
}

@keyframes ripple2 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  10% {
    opacity: 0.6;
  }
  30% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.14;
  }
  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}

@keyframes leftToRight {
  0% {
    transform: rotateX(0) translate(0);
  }
  50% {
    transform: rotateX(0) translate(50px);
  }
  100% {
    transform: rotateX(0) translate(0);
  }
}
